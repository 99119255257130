import { Vue, Component, Prop } from 'vue-property-decorator'
import { makeComputedBinding } from 'shared/util'
import { LogBookUserDefinition, LogBookCredentials, Instance, CompetitionInstance, RaceServer, AdminUser } from 'shared/types/logBook'
import { LogBookRaceItemType } from '../types/racelistitem'
import { setCookie, getCookie } from 'shared/util/cookie'
import { CompetitionType, Competition, CompetitionCreationVals, RaceWithRac, RaceWithoutRac } from '../types/competition'
import { RaceDefinition, RaceDefinitionWrapper } from 'shared/types/erg'
import { inspectlet, identify } from 'shared/util/inspectlet'
import { GlobalConfig } from 'display/config'
import { isEqual } from 'lodash-es'
import { bus } from 'shared/state/Bus'

const axios = require('axios').default

@Component
class LogBookUserModule extends Vue {

	get loggedIn(): boolean {
		return this.inspectCookie || this.logBookUserInfo.id !== -1
	}
	@GlobalConfig() devMode!: boolean
	logBookUserInfo: LogBookUserDefinition = {
		id: -1,
		username: '',
		first_name: '',
		last_name: '',
		gender: '',
		dob: '',
		email: '',
		country: '',
		profile_image: false,
		age_restricted: false,
		roles: []
	}

	credentials: LogBookCredentials = {
		access_token: '',
		expires_in: '',
		refresh_token: ''
	}

	apiEndpoint = ''
	logBookEndpoint = 'https://log-a.concept2.com'

	events = []
	races = []
	participants = []
	waiting = []
	raceServers: RaceServer[] = []
	inspectCookie = !!getCookie('logbooktoken').length
	logins = 0

	@Prop() loading!: boolean


	async login(username: string, password: string): Promise<LogBookUserDefinition | string> {
		const response = await axios.post(this.apiEndpoint + '/user/login',
			{
				username,
				password
			}
		)

		if (response?.data?.r?.status === 401) {
			throw 401
		} else if (response?.data) {
			this.credentials = response.data
			this.inspectCookie = false
			const meResp = await axios.get(this.apiEndpoint + '/user/me', {
				headers: {
					Authorization: `Bearer ${this.credentials.access_token}`
				}
			})
			if (meResp.data && meResp.data.id && meResp.data.id !== 0) {
				this.logBookUserInfo = meResp.data
				setCookie('logbooktoken', this.credentials.access_token, 1)
				this.logins++
				return this.logBookUserInfo
			} else {
				throw meResp?.data?.r?.status
			}
		} else {
			return this.logBookUserInfo
		}
	}

	async getUser(user: string = 'me') {
		return new Promise((resolve, reject) => {
			axios(this.logBookEndpoint + '/api/users/' + user, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
				.then((response) => {
					if (response.data.data && response.data.data.id && response.data.data.id !== 0) {
						if (user === 'me') {
							this.logBookUserInfo = response.data.data
							this.inspectCookie = false
							if(inspectlet) {
								// we are recording this session on inspectlet.com
								identify(this.logBookUserInfo.first_name + ' '
										+ this.logBookUserInfo.last_name,
									this.logBookUserInfo.email)
							}
						}
						resolve({ success: true, data: response.data.data })
					} else {
						if (user === 'me') { this.logout() }
						reject({ success: false, data: response.data.statusText })
					}
				})
				.catch((error) => {
					if (user === 'me') { this.logout() }
					reject({ success: false, data: error })
				})
		})
	}

	logout(): void {
		this.logBookUserInfo = {
			id: -1,
			username: '',
			first_name: '',
			last_name: '',
			gender: '',
			dob: '',
			email: '',
			country: '',
			profile_image: false,
			age_restricted: false,
			roles: []
		}
		this.inspectCookie = false
		this.raceServers = []
		setCookie('logbooktoken', '', -1)
		window.location.href = '/'
		return
	}
	async getCompetition(code: string): Promise<Competition> {
		this.loading = true
		return await axios

			.get(this.logBookEndpoint + `/api/ergrace/competitions/${code}?include=races,races.rac`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
			.then((response) => {
				this.loading = false
				if (response.data) {
					return Promise.resolve(response.data.data)
				} else {
					return Promise.reject(response)
				}
			})
			.catch((error) => {
				this.loading = false
				return Promise.reject(error)
			})
	}
	async getCompetitions(include?: string): Promise<Competition[]> {
		this.loading = true
		try {
		return await axios
		// .get(this.logBookEndpoint + '/api/ergrace/users/me/competitions?include=races,races.rac', {
			.get(this.logBookEndpoint + `/api/ergrace/users/me/competitions${include ? '?include=' + include :''}`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
			.then((response) => {
				this.loading = false
				if (response.data) {
					return Promise.resolve(response.data.data ? response.data.data : response.data)
				} else {
					return Promise.reject(response)
				}
			})
			.catch((error) => {
				this.loading = false
				return Promise.reject(error)
			})
		} catch (error) {
			if (this.devMode) { console.error(error) }
			this.loading = false
			return Promise.reject()
		}
	}
	async deleteCompetition(code: string): Promise<boolean> {
		return await axios
			.delete(this.logBookEndpoint + `/api/ergrace/competitions/${code}`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
			.then((response) => {
				this.loading = false
				if (response.data.message === 'Competition deleted successfully') {
					return Promise.resolve(true)
				} else {
					return Promise.reject(false)
				}
			})
			.catch((error) => {
				this.loading = false
				return Promise.reject(false)
			})
	}
	async updateCompetition(c: CompetitionType): Promise<CompetitionType> {
		this.loading = true
		return await axios({
			method: 'post',
			url: this.logBookEndpoint + `/api/ergrace/competitions/${c.code}`,
			data: c,
			headers: {
				Authorization: `Bearer ${getCookie('logbooktoken')}`
			}
		})
			.then((response) => {
				this.loading = false
				if (response.data) {
					return response.data
				} else {
					return Promise.reject(false)
				}
			})
			.catch((error) => {
				this.loading = false
				return Promise.reject(false)
			})
	}

	async createCompetition(c: CompetitionType | CompetitionCreationVals): Promise<Competition> {
		this.loading = true
		return await axios({
			method: 'post',
			url: this.logBookEndpoint + '/api/ergrace/competitions',
			data: c,
			headers: {
				Authorization: `Bearer ${getCookie('logbooktoken')}`
			}
		})
			.then((response) => {
				this.loading = false
				if (response.data && response.data.data && response.data.data.code) {
					return Promise.resolve(response.data.data)
				} else {
					return Promise.reject(response)
				}
			})
			.catch((error) => {
				this.loading = false
				return Promise.reject(error)
			})
	}

	async getInstances(code: string): Promise<Instance> {
		return await axios
			.get(this.logBookEndpoint + `/api/ergrace/competitions/${code}/instance`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
			.then((response) => {
				if (response.data) {
					// Add competition_code attribute to Instance
					response.data.data.competition_code = code
					return response.data.data
				} else {
					return response
				}
			})
			.catch((error) => {
				return Promise.reject(error)
			})
	}
	async recordInstance(i: Instance, code: string): Promise<Instance> {
		this.loading = true
		return await axios({
			method: 'post',
			url: this.logBookEndpoint + `/api/ergrace/competitions/${code}/instance`,
			data: i,
			headers: {
				Authorization: `Bearer ${getCookie('logbooktoken')}`
			}
		})
			.then((response) => {
				this.loading = false
				if (response.data.data) {
					return Promise.resolve(response.data.data)
				} else {
					return Promise.reject(response)
				}
			})
			.catch((error) => {
				this.loading = false
				return Promise.reject(error)
			})
	}
	async getRaces(code: string, include?: string): Promise<LogBookRaceItemType[]> {
		this.loading = true
		return await axios
			.get(
				this.logBookEndpoint + `/api/ergrace/competitions/${code}/races${include ? '?include=' + include : ''}`,
				{
					headers: {
						Authorization: `Bearer ${getCookie('logbooktoken')}`
					}
				}
			)
			.then((response) => {
				this.loading = false
				if (response.data) {
					return response.data.data ? response.data.data : response.data
				} else {
					return response
				}
			})
			.catch((error) => {
				this.loading = false
				return Promise.reject(error)
			})
	}
	async getRace(raceId: number, include?: string): Promise<RaceWithRac | RaceWithoutRac> {
		this.loading = true
		return await axios
			.get(this.logBookEndpoint + `/api/ergrace/races/${raceId}${include ? '?include=' + include : ''}`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
			.then((response) => {
				this.loading = false
				if (response.data) {
					return response.data.data
				} else {
					return response
				}
			})
			.catch((error) => {
				this.loading = false
				return Promise.reject(error)
			})
	}
	async deleteRace(raceId: number): Promise<any> {
		this.loading = true
		return await axios
			.delete(this.logBookEndpoint + `/api/ergrace/races/${raceId}`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
			.then((response) => {
				this.loading = false
				if (response.data) {
					return response
				} else {
					return response
				}
			})
			.catch((error) => {
				this.loading = false
				return Promise.reject(error)
			})
	}

	async getResults(raceId: number): Promise<any> {
		this.loading = true
		const r = await axios
			.get(this.logBookEndpoint + `/api/ergrace/races/${raceId}/results`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			}).catch(err => {
				this.loading = false
				return
			})
		this.loading = false
		return (r && r.data) ? r.data : ''
	}
	async saveResults(raceId, r: any): Promise<any> {
		// create or update
		this.loading = true
		return await axios({
			method: 'post',
			url: this.logBookEndpoint + `/api/ergrace/races/${raceId}/results`,
			data: r,
			headers: {
				Authorization: `Bearer ${getCookie('logbooktoken')}`
			}
		})
			.then((response) => {
				this.loading = false
				if (response.data.data) {
					return Promise.resolve(response.data.data)
				} else {
					return Promise.reject(response)
				}
			})
			.catch((error) => {
				this.loading = false
				return Promise.reject(error)
			})
	}
	async saveRace(code: string, r: RaceWithRac): Promise<LogBookRaceItemType> {
		return await axios({
			method: !r.id ? 'post' : 'patch',
			url: this.logBookEndpoint + (!r.id ? `/api/ergrace/competitions/${code}/races` : `/api/ergrace/races/${r.id}`),
			data: r,
			headers: {
				Authorization: `Bearer ${getCookie('logbooktoken')}`
			}
		})
			.then((response) => {
			// this.loading = false
				if (response.data.data) {
					return Promise.resolve(response.data.data)
				} else {
					return Promise.reject(response)
				}
			})
			.catch((error) => {
				// this.loading = false
				return Promise.reject(error.response.data)
			})
	}

	async saveRaceOrder(raceId: number, order: number): Promise<LogBookRaceItemType> {
		return await axios({
			method: 'patch',
			url: this.logBookEndpoint + `/api/ergrace/races/${raceId}`,
			data: {order},
			headers: {
				Authorization: `Bearer ${getCookie('logbooktoken')}`
			}
		})
		.then((response) => {
		// this.loading = false
			if (response.data.data) {
				return Promise.resolve(response.data.data)
			} else {
				return Promise.reject(response)
			}
		})
		.catch((error) => {
			// this.loading = false
			return Promise.reject(error.response.data)
		})
	}
	async saveProjected(raceId: number, projected: string): Promise<LogBookRaceItemType> {
		return await axios({
			method: 'patch',
			url: this.logBookEndpoint + `/api/ergrace/races/${raceId}`,
			data: {projected},
			headers: {
				Authorization: `Bearer ${getCookie('logbooktoken')}`
			}
		})
		.then((response) => {
			if (response.data.data) {
				return Promise.resolve(response.data.data)
			} else {
				return Promise.reject(response)
			}
		})
		.catch((error) => {
			return Promise.reject(error.response.data)
		})
	}
	async getRacFile(raceId: string): Promise<any> {
		this.loading = true
		return await axios
			.get(this.logBookEndpoint + `/api/ergrace/races/${raceId}/rac`, {
				headers: {
					Authorization: `Bearer ${getCookie('logbooktoken')}`
				}
			})
			.then((response) => {
				this.loading = false
				if (response.data) {
					return response.data.data
				} else {
					return response
				}
			})
			.catch((error) => {
				this.loading = false
				return Promise.reject(error)
			})
	}
	async saveRacFile(raceId: number, r: any): Promise<RaceDefinitionWrapper> {
		// this.loading = true
		const result = await axios({
			method: 'post',
			url: this.logBookEndpoint + `/api/ergrace/races/${raceId}/rac`,
			data: r,
			headers: {
				Authorization: `Bearer ${getCookie('logbooktoken')}`
			}
		})
		if (this.devMode) { console.log('rac result', result) }
	// this.loading = false
		if (result && result.data && result.data.race_definition) {
			return result.data
		}
		return Promise.reject('did not save')
	}
	async getRaceServers(): Promise<RaceServer[]> {
		return await axios
			.get(`${this.logBookEndpoint}/api/ergrace/servers`)
			.then((response) => {
				if (response.data && response.data.data) {
					this.raceServers = response.data.data
					// this.apiEndpoint = 'https://' + this.raceServers[0].name
					this.getRespondingRaceServer()
					return response.data.data
				} else {
					return Promise.reject([])
				}
			})
			.catch((error) => {
				return Promise.reject(error)
			})
	}
	async getRespondingRaceServer() {
		this.apiEndpoint = ''
		for(let i = 0; i < this.raceServers.length; i++) {
			this.raceServers[i].responding = null
			this.checkResponding(i)
		}
	}
	async checkResponding(i: number) {
		const apiEndpoint = 'https://' + this.raceServers[i].name
		const rs = {...this.raceServers[i]}
	 return await axios.get(`${apiEndpoint}/instance/version`)
			.then((response) => {
				if (response?.status === 200) {
					this.apiEndpoint = apiEndpoint
					rs.responding = true
				} else {
					rs.responding = false
				}
			}).catch(() => {
				rs.responding = false
			}).finally(() => {
				this.raceServers.splice(i, 1, rs)
				return rs.responding
			})
	}

	async getAdmins(competition: string): Promise<AdminUser[]> {
		return await axios({
			method: 'get',
			url: `${this.logBookEndpoint}/api/ergrace/competitions/${competition}/admins`,
			headers: {
				Authorization: `Bearer ${getCookie('logbooktoken')}`
			}
		})
		.then((response) => {
			if (response.data.data) {
				return Promise.resolve(response.data.data)
			} else {
				return []
				// return Promise.reject(response)
			}
		})
			.catch((error) => {
			return []
			// return Promise.reject(error)
		})
	}

	async addAdmin(competition: string, user_id: number | string): Promise<any> {

		return await axios({
			method: 'post',
			url: `${this.logBookEndpoint}/api/ergrace/competitions/${competition}/admins/${user_id}`,
			data: {},
			headers: {
				Authorization: `Bearer ${getCookie('logbooktoken')}`
			}
		})
		.then((response) => {
			if (response.data.data) {
				return Promise.resolve(response.data.data)
			} else {
				return Promise.reject(response)
			}
		})
		.catch((error) => {
			return Promise.resolve(error)
		})
	}
	async removeAdmin(competition: string, user_id: number): Promise<boolean> {
		return await axios({
			method: 'delete',
			url: `${this.logBookEndpoint}/api/ergrace/competitions/${competition}/admins/${user_id}`,
			headers: {
				Authorization: `Bearer ${getCookie('logbooktoken')}`
			}
		})
			.then((response) => {
			if (response.data.success) {
				return Promise.resolve(true)
			} else {
				return Promise.reject(false)
			}
		})
			.catch((error) => {
			return Promise.reject(false)
		})
	}
}

export const LogBookUser = new LogBookUserModule()
export const LogBookUserState = makeComputedBinding(LogBookUser)
