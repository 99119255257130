
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { RemoteErgPen, RemoteErgStatePen, Erg, ErgState } from 'shared/state'

import { bus } from 'shared/state/Bus'
import { config, GlobalConfig } from 'display/config'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { LogBookUserState } from 'shared/state/LogBookUser'
import { LogBookUserDefinition } from 'shared/types/logBook'
import { RaceWithRac } from 'shared/types/competition'
import date from 'date-and-time'

import { ActionType, } from 'shared/types/grants'

@Component({ components: {} })
export default class PermissionModal extends Vue {

	@CompetitionsState() currentRaceServer!: string
	@CompetitionsState() currentCompetitionCode!: string
	@LogBookUserState('logBookUserInfo') user!: LogBookUserDefinition
	@GlobalConfig() overrideRacePermissions!: boolean
	@GlobalConfig() storedRaceId!: number

	config = config.vals
	comp = Competitions
		// user = this.logBookUserInfo

	action: any = {}
	cancelTitle = 'Cancel'
	defaultTitle = 'Ok'
	secondOptionTitle = ''
	defaultSpinning = false
	secondOptionSpinning = false
	race: RaceWithRac | null = null
	lastNotifiedStale = new Date()

	HP = RemoteErgPen
	accessType = ''

	get isSmallScreen() {
		return !!(window.screen.width < 700)
	}

	get gotServer() {
		if(this.permissionServer && this.permissionServer.server) {
			return true
		}
		return false
	}

	get permissionServerState() {
		if(this.gotServer) {
			return this.permissionServer.server.state
		}
		return 'disconnected'
	}

	btnPressed(btnText: string) {

		let cmd: ActionType | '' = ''
		let response = ''
		switch (btnText) {
			case 'take control':
				cmd = 'take_control'
				this.defaultSpinning = true
				break
			case 'request control':
				cmd = 'request_control'
				this.secondOptionSpinning = true
				break
			case 'grant':
				cmd = 'grant_control'
				response = 'allow'
				this.close()
				RemoteErgPen.setRaceOrganizer(false)
				bus.$emit('cancel_run_race')
				bus.$emit('cancel')
				break
			case 'deny':
				cmd = 'grant_control'
				response = 'deny'
				this.close()
				break
			case 'ok':
				this.close()
				if (this.action.alert === 'allow' && this.action.type === 'delete') {
					bus.$emit('delete_race', this.race)
				}
				return
			case 'cancel':
				this.close()
				bus.$emit('cancel_run_race')
				bus.$emit('cancel')
				return
		}

		let packet: any = {}
		if (cmd === 'grant_control') {
			packet = {
				action: cmd,
				detail: {
					id: this.action.message.id,
					race: this.action.race,
					type: this.action.type,
					competition: this.currentCompetitionCode,
					grant: response
				}
			}
		} else {
			packet = {
				action: cmd,
				detail: {
					username: this.user.username,
					first_name: this.user.first_name,
					last_name: this.user.last_name,
					competition: this.currentCompetitionCode
				}
			}
			if (this.action.race) {
				packet.detail.race = this.action.race
				packet.detail.type = this.action.type
			}
			if (cmd === 'request_control' || cmd === 'take_control') {
				packet.detail.race = this.race?.id
				packet.detail.type = this.accessType
			}
		}
		if (this.gotServer) {
			this.permissionServer.server.send(packet)
		}
	}

	close() {
		this.$bvModal.hide('modal-permission')
	}
	get message() {
		return this.action ?.message?.text || this.action?.notification?.message || this.action?.message || ''
	}

	get permissionServer() {
		return this.comp.permissionServers.find(m => {
			const abbr = m.permission ?.server ?.wsUrl.split('secure-socket/')[1].split('/')[0]

			return abbr === this.currentRaceServer.split('.')[0]

		})
	}
	// handles cases such as on iPhone Safari when socket is closed during sleep mode
	reconnectPermissionSocket() {
		if(this.overrideRacePermissions) { return }
		if(this.permissionServer && this.permissionServerState !== 'connected') {
			this.permissionServer.server.connect()
		}
	}

	handleAttemptingRaceAccess(race: RaceWithRac, accessType = 'run') {
		this.reconnectPermissionSocket()
		this.race = race
		this.accessType = accessType

		if (accessType === 'view') {
			bus.$emit('view', this.race)
			RemoteErgPen.setRaceOrganizer(true)
			return
		}

		if (this.overrideRacePermissions) {
			if (accessType === 'run') {
				bus.$emit('run', this.race)
				RemoteErgPen.setRunningRace(this.currentCompetitionCode, race.id)
				RemoteErgPen.setRaceOrganizer(true)
				return
			}
			if (accessType === 'delete') {
				bus.$emit('delete_race', this.race)
				return
			}
			bus.$emit('edit', this.race)
			return
		}
		if (this.gotServer) {
			// alert(JSON.stringify({action: 'request_race',
			// detail: { username: this.user.username, first_name: this.user.first_name,
			// last_name: this.user.last_name, competition: this.currentCompetitionCode,
			// race: race.id, type: accessType }}))
			this.permissionServer.server.send({
				action: 'request_race',
				detail: {
					username: this.user.username,
					first_name: this.user.first_name,
					last_name: this.user.last_name,
					competition: this.currentCompetitionCode,
					race: race.id,
					type: accessType
				}
			})
		}
	}
	handleAttemptingCompetition(competition: string) {
		this.reconnectPermissionSocket()
		if (this.gotServer) {
			this.permissionServer.server.send({
				action: 'request_competition',
				detail: {
					username: this.user.username,
					first_name: this.user.first_name,
					last_name: this.user.last_name,
					competition
				}
			})
		}
	}
	 @Watch('race', {
		immediate: true
	})
	 raceChanged(newVal, oldVal) {
		console.log(this.comp.permissionServers, this.currentRaceServer)
		if (this.gotServer && oldVal?.id && newVal.id !== oldVal.id) {
			this.permissionServer.server.send({
				action: 'relinquish_race',
				detail: {
					username: this.user.username,
					first_name: this.user.first_name,
					last_name: this.user.last_name,
					competition: this.race?.competition,
					race: oldVal.id
				}
			})
			if(!this.overrideRacePermissions) { RemoteErgPen.setRaceOrganizer(false) }
		}
	}
	 @Watch('currentCompetitionCode', {
		immediate: true
	})
	competitionChanged(newVal, oldVal) {
		this.reconnectPermissionSocket()
		if (this.gotServer) {
			if (oldVal) {
				this.permissionServer.server.send({
					action: 'relinquish_competition',
					detail: {
						username: this.user.username,
						first_name: this.user.first_name,
						last_name: this.user.last_name,
						competition: oldVal
					}
				})
				RemoteErgPen.setRaceOrganizer(false)
			}
			if (newVal) {
				this.permissionServer.server.send({
					action: 'request_competition',
					detail: {
						username: this.user.username,
						first_name: this.user.first_name,
						last_name: this.user.last_name,
						competition: newVal
					}
				})
			}
		}
	}

	 runIfRaceGrant() {
		if (this.action.race && this.action.race === this.race?.id) {
			bus.$emit('run', this.race)
			RemoteErgPen.setRaceOrganizer(true)
			this.accessType = ''
				// config.set({ global: { storedRaceId: this.race?.id } })
		}
	}

	 editIfRaceGrant() {
		if (this.action.race && this.action.race === this.race?.id) {
			bus.$emit('edit', this.race)
			this.accessType = ''
		}
	}

	 handlePermissionNotification(action: any) {
		this.defaultSpinning = false
		this.secondOptionSpinning = false


		if (action.alert && action.alert === 'HP') {
			if (action.competition === this.currentCompetitionCode) {
				if (action.type === 'run') {
					RemoteErgPen.setRunningRace(action.competition, action.race || this.race?.id)
				}
				if (action.type === 'freed') {
					RemoteErgPen.setRunningRace('', '')
				}
			}
			return
		}
		if (action.alert && action.alert === 'notification' &&
			action.notification?.competition && action.notification.competition === this.currentCompetitionCode &&
			action.notification?.message) {
			if (action.notification.message === 'Update your race list' && this.permissionServer) {
				// next line: see notes on handleRaceListNotification()
				this.lastNotifiedStale = new Date()
				bus.$emit('update_race_list')
				return
			}
			this.secondOptionTitle = ''
			this.defaultTitle = 'ok'
			this.cancelTitle = ''
			this.$bvModal.show(`modal-permission`)

		}

		this.action = action
		if (action.grant && action.grant === 'allow' && action.competition && !action.race && this.gotServer) {
			// got permission to competition
			this.permissionServer.server.send({
				action: 'get_store'
			})
		}

		if (action.grant && action.grant === 'deny') {
			this.defaultTitle = 'take control'
			this.secondOptionTitle = 'request control'
			this.cancelTitle = 'cancel'
			this.$bvModal.show(`modal-permission`)
		}

		if (action.grant && action.grant === 'allow' && action.race && action.type === 'run') {
			this.runIfRaceGrant()
		}

		if (action.grant && action.grant === 'allow' && action.race && action.type === 'edit') {
			this.editIfRaceGrant()
		}

		if (action.grant && action.grant === 'allow' && action.race && action.type === 'delete') {
			bus.$emit('delete_race', this.race)
		}

		if (action.alert && action.alert === 'request') {
			this.secondOptionTitle = ''
			this.defaultTitle = 'grant'
			this.cancelTitle = 'deny'
			this.$bvModal.show(`modal-permission`)
		}
		if (action.alert && action.alert === 'allow' && action.race && action.type === 'run') {
			this.secondOptionTitle = ''
			this.defaultTitle = 'ok'
			this.cancelTitle = ''
			this.$bvModal.show(`modal-permission`)
			this.runIfRaceGrant()
		}

		if (action.alert && action.alert === 'allow' && action.type === 'edit') {
			this.secondOptionTitle = ''
			this.defaultTitle = 'ok'
			this.cancelTitle = ''
			this.$bvModal.show(`modal-permission`)
			this.editIfRaceGrant()
		}

		if (action.alert && action.alert === 'allow' && action.type === 'delete') {
			this.secondOptionTitle = ''
			this.defaultTitle = 'ok'
			this.cancelTitle = ''
			this.$bvModal.show(`modal-permission`)
		}

		if (action.alert && action.alert === 'deny') {
			this.secondOptionTitle = ''
			this.defaultTitle = 'ok'
			this.cancelTitle = ''
			RemoteErgPen.setRaceOrganizer(false)
			bus.$emit('cancel_run_race')
			bus.$emit('cancel')
			this.$bvModal.show(`modal-permission`)
		}

	}
	 handleStoreNotification(store: any[]) {
		const userAccessLevel = store.find(s => s.competition === this.currentCompetitionCode && s.race && s.type === 'run')
		if (userAccessLevel && !this.overrideRacePermissions) {
			RemoteErgPen.setRunningRace(userAccessLevel.competition, userAccessLevel.race)
		}
	}

	 handleRaceListNotification() {
		// before notifying other users to update their race list,
		// check that
		// 1.) client is connected to the socket
		// 2.) it has been at least a few seconds between notifications
		// due to vue-draggable control appears to be mutating the projected-time control events
		// therefore, when two browsers are connected they could relay the same event back and forth

		const now = new Date()
		if (this.gotServer && (date.subtract(now, this.lastNotifiedStale).toSeconds() > 4)) {
			// got permission to competition
			this.permissionServer.server.send({
				action: 'notify',
				detail: {
					competition: this.currentCompetitionCode,
					message: 'Update your race list'
				}
			})
			this.lastNotifiedStale = new Date()
		}
	}

	 created() {
		bus.$on('permission', this.handlePermissionNotification)
		bus.$on('store', this.handleStoreNotification)
		bus.$on('attempt_manage_race', this.handleAttemptingRaceAccess)
		bus.$on('attempt_manage_competition', this.handleAttemptingCompetition)
		bus.$on('notify_others_race_list_stale', this.handleRaceListNotification)
		bus.$on('refresh_competition_request', this.competitionChanged)
	}

	 beforeDestroy() {
		bus.$off('permission', this.handlePermissionNotification)
		bus.$off('store', this.handleStoreNotification)
		bus.$off('attempt_manage_race', this.handleAttemptingRaceAccess)
		bus.$off('attempt_manage_competition', this.handleAttemptingCompetition)
		bus.$off('notify_others_race_list_stale', this.handleRaceListNotification)
		bus.$off('refresh_competition_request', this.competitionChanged)
	}
}
