
import { Component, Prop, Vue } from 'vue-property-decorator'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import { GlobalConfig } from 'display/config'

@Component({ components: {  } })
export default class LoginForm extends Vue {
	@GlobalConfig() devMode!: boolean
	@LogBookUserState() apiEndpoint!: string
	@LogBookUserState() raceServers!: any
	form = {
		username: '',
		password: '',
		access_code: ''
	}
	errorMessage = ''

	show: boolean = true
	spinning: boolean = false

	get disabled(): boolean {
		return !(this.apiEndpoint.length > 0)
	}

	clearError() {
		this.errorMessage = ''
	}

	async onSubmit(evt) {
		this.errorMessage = ''
		this.spinning = true
		evt.preventDefault()

		try {
			await LogBookUser.login(
				this.form.username,
				this.form.password
			)
		} catch (err) {
			if (err.message === 'Network Error') {
				this.errorMessage = `Unable to reach Concept 2.
					Please check your internet connection.
					If issue persists, please contact C2 tech support.`
			} else if (err === 401) {
				// unauthorized
				if(this.form.username.includes('@')) {
				this.errorMessage = 'Please login using your Concept2 Online Logbook username which may be different than your email address'
				} else {
				this.errorMessage =
					'Invalid Username or Password. Please correct and resubmit or contact C2 tech support if issue persists.'
				}
			} else if (err === 999) {
				// unauthorized
				this.errorMessage =
					'Invalid Access Code. Please correct and resubmit or you may request the access code from virtualracing@concept2.com.'
			} else {
				// unknown error
				this.errorMessage =
					`An error occurred. Please contact C2 tech support.`
				if(this.devMode) { console.error(err) }
			}
		}
		this.spinning = false
	}
}
